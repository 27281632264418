<template>
  <v-container>
    <v-row>
      <div class="general-details">
        <TabNavigation :tabs="tabs" :currentDispatcherId="this.$route.params.id"/>
        <div class="text-h3 font-weight-light">Under Development</div>

      </div>
    </v-row>
  </v-container>
</template>

<script>
import TabNavigation from '@/components/TabNavigation.vue';
import { getDispatcherById } from '@/services/admin';

export default {
  components: {
    TabNavigation
  },
  data() {
    return {
      tabs: [
        { name: 'General', path: '/dispatcher-general-details' },
        { name: 'Bookings', path: '/dispatcher-bookings' },
        { name: 'Drivers', path: '/dispatcher-drivers' },
        { name: 'Vehicles', path: '/dispatcher-vehicles' },
        { name: 'Transactions', path: '/dispatcher-transactions' }
      ],

      loading: false // To indicate loading state
    };
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>
.general-details {
  width: 100%;
  padding: 20px;
}
</style>
